import React from "react";
import { useState } from "react";
import "../App.css";
import axios from "axios";

export default function Giftko() {
  const [age, setAge] = useState(0);
  const [gender, setGender] = useState("Female");
  const [occasion, setOccasion] = useState("Birthday");
  const [otherOccasion, setOtherOccasion] = useState("");
  const [relationship, setRelationship] = useState("Friend");
  const [otherRelationship, setOtherRelationship] = useState("");
  const [details, setDetails] = useState("");

  const [formData, setFormData] = useState({ description: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // try {
    //   const response = await axios.get("/api/recommendations", {
    //     params: {
    //       description: createFromData()
    //     }
    //   });

    //   console.log(response.data.message);

    // } catch (error) {
    //   console.log(error);
    // }
  }

  const createFromData = () => {
    let tempOccasion = occasion;
    let tempRelationship = relationship;

    if (occasion == "other") {
      tempOccasion = otherOccasion;
    }

    if (relationship == "other") {
      tempRelationship = otherRelationship;
    }

    let description = `The person is ${age} years old and identifies as ${gender} gender. The occasion is ${tempOccasion}. The relationship with the gift maker is ${tempRelationship}. More info ${details}`;

    return description;
  }
  return (
    <div className="d-flex justify-content-center align-items-center wrapper giftko">
      <div className="card w-75 m-5">
        <div className="overlay">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <h1>Coming soon!</h1>
          </div>
        </div>
        <div className="card-body giftko-form">
          <div className="card-title">
            <h4>Fill the form and get personalised gift ideas</h4>
          </div>
          <div className="card-text mt-4">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="age" className="form-label">How old is the person that you want to make a present for?</label>
                <input maxLength={120} value={age} onChange={e => setAge(e.target.value)} type="number" min="0" className="form-control" id="age" />
              </div>
              <div className="mb-3">
                <label htmlFor="gender" className="form-label">What is their gender?</label>
                <select value={gender} onChange={e => setGender(e.target.value)} className="form-select" id="gender" aria-label="Default select example">
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="occasion" className="form-label">What is the occasion?</label>
                <select value={occasion} onChange={e => setOccasion(e.target.value)} className="form-select" id="occasion" aria-label="Default select example">
                  <option value="birthday">Birthday</option>
                  <option value="anniversary">Anniversary</option>
                  <option value="graduation">Graduation</option>
                  <option value="wedding">Wedding</option>
                  <option value="babyshower">Babyshower</option>
                  <option value="jobpromotion">Job promotion</option>
                  <option value="christmas">Christmas</option>
                  <option value="other">Other</option>
                </select>
                {(occasion == "other") ? <input maxLength={25} type="text" value={otherOccasion} onChange={e => setOtherOccasion(e.target.value)} className="form-control mt-2" id="otherOccasion" placeholder="Please specify the occasion" /> : null}
              </div>
              <div className="mb-3">
                <label htmlFor="relation" className="form-label">What is your relationship with the person?</label>
                <select value={relationship} onChange={e => setRelationship(e.target.value)} className="form-select" id="relationship" aria-label="Default select example">
                  <option value="friend">Friend</option>
                  <option value="sibling">Sibling</option>
                  <option value="parent">Parent</option>
                  <option value="coworker">Coworker</option>
                  <option value="partner">Partner</option>
                  <option value="other">Other</option>
                </select>
                {(relationship == "other") ? <input maxLength={25} type="text" value={otherRelationship} onChange={e => setOtherRelationship(e.target.value)} className="form-control mt-2" id="otherRelationship" placeholder="Please specify the relationship" /> : null}
              </div>
              <div className="mb-3">
                <label htmlFor="details" className="form-label">What are their passions, free time activities, favourite films, places etc.</label>
                <textarea maxLength={300} value={details} onChange={e => setDetails(e.target.value)} className="form-control" id="details" rows="5" placeholder="Describe the person in as much detail as possible. The more you write here, the better are the recommendations"></textarea>
              </div>
              <div className="mb-3">
                <button type="submit" className="btn btn-primary">Get gift ideas!</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}